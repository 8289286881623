<template>
  <div style="overflow-y: auto;">
 <div id="calc_results_to_print" >
   <div style="display: flex; justify-content: space-between">
     <div>
       <div><h3>Результаты расчетов по землетрясению</h3></div>
       <div>Дата: {{getUTCDateText()}}</div>
       <div>Координаты: {{textLatitude}}, {{textLongitude}}, магнитуда {{magnitude}} </div>
     </div>

   </div>
   <!--{{signal}}-->
    <!--<div style="background-color: lightgray"><a href="#">Получить результаты</a><a href="#" style="padding-left: 10px">Справка</a></div>-->
   <div style=" height: 70vh">
    <table class="result-table;" >
      <thead>
        <tr>
          <th>Защищаемый пункт</th>
          <th>
            <div>Расчет первой волны</div>
            <div>Время прихода</div>
            <div>Расчетная высота (м)</div>
          </th>
          <th>
            <div>Виртуальный источник:</div>
            <div style="padding-left: 15px; color: blue">Минимальная волна (м)</div>
            <div style="padding-left: 30px">Прилив</div>
            <div style="padding-left: 45px">Уровень моря</div>
          </th>
          <th>
            <div>Виртуальный источник:</div>
            <div style="padding-left: 15px; color: red">Максимальная волна (м)</div>
            <div style="padding-left: 30px">Прилив</div>
            <div style="padding-left: 45px">Уровень моря</div>
          </th>
          <th>
            <div>Виртуальный источник:</div>
            <div style="padding-left: 15px; color: blue">Время прихода минимальной волны</div>
            <div style="padding-left: 30px; color: red">Время прихода максимальной волны</div>
            <div style="padding-left: 45px"> </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <!--<tr>-->
          <!--<td colspan="5" @click="showValues = !showValues" style="border: 1px solid black; background-color: lightgrey; cursor: pointer">Раскрыть/Скрыть</td>-->
        <!--</tr>-->
        <tr>
          <td colspan="5"></td>
        </tr>
      </tbody>
      <result v-show="showValues" v-for="(report, index) in getCalcResults" :key="report.type + index" :report="report" :earthquake-date="signal.earthquakeDate"></result>
    </table>
   </div>
 </div>
  </div>
</template>

<script>
  import Result from "./Result"
  import {mapActions, mapGetters} from "vuex";
  import {getDateTime} from "../../utils/time";

  export default {
    name: 'CalcResults',
    components: {
      Result
    },
    props: {
      signal: Object,
    },
    computed: {
      ...mapGetters(['getCalcResults']),
      textDate() {
        // earthquakeDate
        return ``
      },
      textLatitude() {
        return `${this.latitude} с.ш.`
      },
      textLongitude() {
        return `${this.longitude} в.д.`
      }
    },
    data() {
      return {
        latitude: this.signal.latitude,
        longitude: this.signal.longitude,
        magnitude: this.signal.magnitude,
        signalId: this.signal.signalId,

        showValues: true,
        results: []
      }
    },
    methods: {
      ...mapActions(['loadCalcResults']),
      getUTCDateText() {

        let date = new Date(this.signal.earthquakeDate)
        return getDateTime(date, false) + ' ВСВ'// + ' (' + translate + ' + '+ offset + ')'
      }

    },
    async mounted() {
      console.debug('calc results', this.signal)
      this.loadCalcResults(this.signal.id)
      // let resp = await getResults(this.signal.id);
      // this.results = resp.payload
      // logger('resp results', JSON.stringify(resp))
    }
  }
</script>

<style scoped>
  table tbody tr th, table tbody tr td, table th, table td {
    padding: 5px;
  }

  table, table th {
    border: 1px solid black;
  }

  table.result-table td {
    padding: 5px;
  }

  @media print {
    .media-hidden {
      display: none;
    }
  }

</style>