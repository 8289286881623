import axiosClient from "./axiosWrapper";

export const getAction = async (signalId) => {
  let result = await axiosClient().get(`/actions/${signalId}`)

  return result
}

export const doAction = async (signalId, actionId, resultFlag) => {
  let result = await axiosClient().post(`/actions/${signalId}/${actionId}`, {"updateType": resultFlag ? 'ACTION_DONE' : 'ACTION_SKIPPED'})

  return result
}