<template>
  <tbody>
  <tr style="border-bottom: 1px solid black" class="centered">
    <td colspan="5">{{report.type}}</td>
  </tr>
  <tr v-for="(result, index) in report.results" :key="result.name + index" class="bordered">
    <td>
      <div><b>{{result.name}}</b> </div>
      <div v-if="result.latitude && result.longitude">{{result.latitude}} с.ш. {{result.longitude}} в.д.</div>
    </td>
    <td>
      <div>{{result.firstWaveTime ? result.firstWaveTime : getUTCDateWithOffset(result.time)}}</div>
      <div>{{result.waveHeight ? result.waveHeight.toFixed(2): '-'}}</div>
    </td>
    <td>
      <div style="display: flex; justify-content: flex-end">
        <!--<colored-line color="blue" :width="calcWidth(result.minWaveHeight, result.waveHeight)"/>-->
        <!--<div style="color: blue">&nbsp; {{result.minWaveHeight ? result.minWaveHeight : result.waveHeight}}</div>-->

        <colored-line color="blue" :width="calcWidth(result.minWaveHeight)"/>
        <div style="color: blue">&nbsp; {{result.minWaveHeight}}</div>
      </div>
      <div> &nbsp; </div>
      <div> &nbsp; </div>
    </td>
    <td>
      <div style="display: flex">
        <div style="color: red">{{result.maxWaveHeight}}&nbsp;&nbsp;</div>
        <colored-line color="red" :width="calcWidth(result.maxWaveHeight)"/>
      </div>
      <div> &nbsp; </div>
      <div> &nbsp; </div>
    </td>
    <td>
      <div> &nbsp;</div>
      <div style="display: flex; justify-content: space-between">
        <colored-line color="blue" :width="calcTimeWidth(result.minWaveTime)"/>&nbsp;
        <div>{{getUTCDateWithOffset(result.minWaveTime)}}</div>
      </div>
      <div style="display: flex;justify-content: space-between">
        <colored-line color="red" :width="calcTimeWidth(result.maxWaveTime)"/>&nbsp;
        <div>{{getUTCDateWithOffset(result.maxWaveTime)}}</div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>

  import ColoredLine from "./ColoredLine"
  import {getDateTime} from "../../utils/time";

  export default {
    name: 'result',
    components: {
      ColoredLine
    },
    props: {
      report: Object,
      earthquakeDate: String
    },
    data() {
      return {

      }
    },
    methods: {
      calcWidth(value, value2) {
        if (value) return (value * 1.5) + 3
        if (value2) return (value2 * 1.5) + 3
        return 3
      },
      calcTimeWidth(value, value2) {
        if (value) return (value / 2000) + 3
        if (value2) return (value2 / 2000) + 3
        return 3
      },
      getUTCDateWithOffset(offset) {
        if (offset !== 0 && !offset) return '';

        let date = new Date(this.earthquakeDate)
        // let translate = getDateTime(date, true)
        if (offset) {
          date.setSeconds(date.getSeconds() + +offset)
        }
        return getDateTime(date, false) + ' ВСВ'// + ' (' + translate + ' + '+ offset + ')'
      }
    }
  }
</script>

<style scoped>

  tr.centered td {
    text-align: center;
    padding: 5px 0;
  }

  tr td {
    padding: 5px;
  }

  tr.bordered td {
    border: 1px solid black;
    padding: 5px 15px;
  }
</style>