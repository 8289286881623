<template>
    <div  style="min-width: 260px; padding: 0 20px 20px 20px" class="md-rewrite">
      <div style="margin: auto; flex-direction: column; justify-content: center; align-items: center; overflow-y: auto; height: 90%; " >
        <div class="text-center">
          <h3 style="margin-bottom: 20px">Создать новый сигнал</h3>

        </div>
        <md-field class="dialogField" :class="{'md-invalid': !earthquakeDateValid}" >
          <label>Дата и время землетрясения (ВСВ)</label>
          <md-input :value="earthquakeDate" placeholder="Дата и время землетрясения (ВСВ)" disabled></md-input>
          <span class="md-error">Формат должен быть ГГГГ-ММ-ДД ЧЧ:ММ</span>
        </md-field>
        <md-field class="dialogField" :class="{'md-invalid': !earthquakeDateValid}" >
          <label>Дата и время землетрясения (Локальное)</label>
          <md-input :value="earthquakeDateLocal" placeholder="Дата и время землетрясения (ВСВ)" disabled></md-input>
          <span class="md-error">Формат должен быть ГГГГ-ММ-ДД ЧЧ:ММ</span>
        </md-field>
        <div>
          <div>ВСВ</div>
          <div>
            <input
                    v-model="selectedDate"
                    type="datetime-local"
                    id="meeting-time"
                    name="meeting-time"
                    min="2024-01-01T00:00" />
          </div>
        </div>
        <br/>
        <md-checkbox v-model="exercise" class="md-primary">Учебная</md-checkbox>

        <md-field class="dialogField" :class="{'md-invalid': !latitudeValid}" >
          <label>Широта, град северной широты</label>
          <md-input v-model.trim="latitude" placeholder="Широта"  type="number" min="-90" max="90" step="0.01"></md-input>
          <span class="md-error">Поле должно быть валидным, мин -90, макс 90</span>
        </md-field>

        <md-field class="dialogField" :class="{'md-invalid': !longitudeValid}">
          <label>Долгота, град восточной долготы</label>
          <md-input v-model.trim="longitude" placeholder="Долгота"  type="number" min="-180" max="180" step="0.01"></md-input>
          <span class="md-error">Поле должно быть валидным, мин -180, макс 180</span>
        </md-field>

        <md-field class="dialogField" :class="{'md-invalid': !magnitudeValid}">
          <label>Магнитуда землетрясения</label>
          <md-input v-model.trim="magnitude" placeholder="Магнитуда землетрясения"  type="number" min="0" max="10" step="0.1"></md-input>
          <span class="md-error">Поле не может быть пустым, мин 1, макс 10</span>
        </md-field>

        <md-field class="dialogField" :class="{'md-invalid': !depthValid}">
          <label>Глубина гипоцентра, км</label>
          <md-input v-model.trim="depth" placeholder="Глубина гипоцентра, км"  type="number" min="-100000" max="100000"></md-input>
          <span class="md-error">Поле не может быть пустым</span>
        </md-field>

        <md-checkbox v-model="tsunamigenic" value="true" class="md-secondary">Цунамигенное</md-checkbox>


        <div style="text-align: center">
        <md-button  @click="createSignal()" class="md-raised md-primary" style="margin-top: 40px">Создать</md-button>
        </div>
      </div>
    </div>
</template>

<script>

  import {postSignal} from "../../api/signal-controller";
  import logger from "../../utils/logger";
  import {getDateTime2, getFormattedDate, getFormattedTime} from "../../utils/timezone";
  import {dateInputToDateString, getUtcDateFromInput} from "../../utils/time";
  const dateReg = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d$/
  import {mapActions} from 'vuex'

  export default {
    name: 'SignalForm',
    data() {
      return {
        earthquakeDate: getDateTime2(new Date(), true),
        earthquakeDateLocal: getDateTime2(new Date(), false),
        exercise: false,
        latitude: 90,
        longitude: 180,
        magnitude: 1,
        depth: 0,
        tsunamigenic: false,
        selectedDate: getUtcDateFromInput(getFormattedDate('UTC', new Date()).replaceAll(", ", "T"))
      }
    },
    watch: {
      selectedDate: {
        handler: function (val) {
          console.log('watch time ', val, getUtcDateFromInput(val))
          this.earthquakeDate = getUtcDateFromInput(val, 'intl')
          // this.earthquakeDate = getDateTime2(val, true)
          this.earthquakeDateLocal = getDateTime2(val)
          // try {
          //   this.earthquakeDate = Date.UTC()
          // } catch (e) {
          //   //ignore
          // }
          // // if (val instanceof  Date || (val instanceof String && Date.parse(val))) {
          //   this.earthquakeDate = this.selectedDate
          // }
          /* ... */
        },
        immediate: true
      }
    },
    computed: {
      formValid() {
        return this.earthquakeDateValid && this.latitudeValid && this.longitudeValid && this.magnitudeValid && this.depthValid
      },
      earthquakeDateValid() {
        console.log('test', this.selectedDate)
        return dateReg.test(this.selectedDate)
      },
      latitudeValid() {
        return this.latitude >=-90 && this.latitude <= 90
      },
      longitudeValid() {
        return this.longitude >=-180 && this.longitude <= 180
      },
      magnitudeValid() {
        return this.magnitude >= 1 && this.magnitude <= 10
      },
      depthValid() {
        return this.depth >= -100000 && this.depth <= 100000
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      timeChanged() {
        this.showInfoAlert(this.selectedDate)
      },
      async createSignal() {
        if (!this.formValid) return;
        let result = await postSignal(
          dateInputToDateString(this.selectedDate),
          this.exercise,
          this.latitude,
          this.longitude,
          this.magnitude,
          this.depth,
          this.tsunamigenic,
          false
        )
        logger('post signal result', result)
        this.$emit('closeSignalForm')
      }
    },
    mounted() {
      let initDate = getDateTime2(new Date(), true)
      let wow = getFormattedTime('UTC', new Date())
      logger('mount signal modal', wow, initDate, initDate.replaceAll(", ", "T"))
    }
  }
</script>

<style>
  .dialogField {
    width: 400px;
    max-width: 96%;
  }

  .mo-rewrite .md-field {
    margin: 5px 0 5px;
  }

</style>