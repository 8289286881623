import axiosClient from "./axiosWrapper";

export const getTemplateMessages = async (signalId) => { //message templates
  let result = await axiosClient().get(`/templates/messages${signalId ? '/' + signalId : ''}`)

  return result
}

export const getSeismologistTemplateMessages = async () => { //message templates for seismologist
  let result = await axiosClient().get(`/templates/seismologist_messages`)

  return result
}